import { ChangeEvent, useCallback, useState } from "react";
import Dropdown from "../../common/components/Dropdown";
import {
  SelectorsLocalStorage,
  SptLocalStorageVariables,
} from "../../common/constants/localStorage";
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "../../common/helpers";
import X2JS from "x2js";
import { Wrapper, SearchButtonStyled } from "./styled";
import { Input, Loader } from "../../common/components";
import { autoPartsSearch, fetchSMTPData } from "../../api/autoParts";
import { statesList } from "../../common/constants/statesList";
import { useGlobalContext } from "../../context";

const storageLicensePlate =
  getLocalStorageValue(SelectorsLocalStorage.CURRENT_LICENSE_PLATE) || "";

const LicensePlate= () => {
  const [isLoading, setIsLoading] = useState(false);
  const [licensePlate, setLicensePlate] = useState<string>(storageLicensePlate);
  const [stateId, setStateId] = useState<string>("");
  const [selectedLabel, setSelectedLabel] = useState<string>("");

  const { state, dispatch } = useGlobalContext();

  const { selectorModalConfig, store, year, make, model, product } = state;

  const licenseKey = selectorModalConfig?.license_key;
  const databaseUrl = selectorModalConfig?.database_url;

  const x2js = new X2JS();

  const handleChangeLicensePlate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setLicensePlate(e.target.value);
    },
    [licensePlate]
  );

  const handleSearch = async () => {
    setIsLoading(true);
    setLocalStorageValue(
      SelectorsLocalStorage.CURRENT_LICENSE_PLATE,
      licensePlate
    );
    if (!licenseKey || !databaseUrl) return;
    const smtpResponse = await fetchSMTPData({
      id: licenseKey,
      url: databaseUrl,
      licensePlate,
      stateId: stateId,
    });
    const data: any = x2js?.xml2js(smtpResponse?.data);
    const smtpResult = data?.ShowMeThePartsVinApps.vinapps;
    const response: any = await autoPartsSearch(store, {
      year: smtpResult.year,
      make: smtpResult.makeid,
      model: smtpResult.modelid,
      engine: smtpResult.engineid,
    });
    dispatch({
      type: "setYear",
      payload: { ...year, value: smtpResult.year, label: smtpResult.year },
    });
    dispatch({
      type: "setMake",
      payload: { ...make, value: smtpResult.makeid, label: smtpResult.make },
    });
    dispatch({
      type: "setModel",
      payload: { ...model, value: smtpResult.modelid, label: smtpResult.model },
    });
    dispatch({
      type: "setProduct",
      payload: {...product, label: "", value: ""},
    });
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_YEAR_KEY_NAME,
      smtpResult.year
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_YEAR_VALUE_NAME,
      smtpResult.year
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MAKE_KEY_NAME,
      smtpResult.makeid
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MAKE_VALUE_NAME,
      smtpResult.make
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MODEL_KEY_NAME,
      smtpResult.modelid
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MODEL_VALUE_NAME,
      smtpResult.model
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_PARTTYPE_KEY_NAME,
      ""
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_PARTTYPE_VALUE_NAME,
      ""
    );
    setLocalStorageValue(SelectorsLocalStorage.SELECTED_ENGINE_KEY_NAME, "");
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_ENGINE_VALUE_NAME,
      ""
    );
    localStorage.setItem(
      SptLocalStorageVariables.SPT_SELECTORS,
      JSON.stringify({
        year: smtpResult.year,
        make: smtpResult.makeid,
        model: smtpResult.modelid,
        product: "",
        engine: "",
      })
    );
    dispatch({ type: "setData", payload: response.data });
    sessionStorage.removeItem(SptLocalStorageVariables.SPT_FILTERS);
    setIsLoading(false);
  };

  const handleChange = ({ value, label }: { value: string; label: string }) => {
    setStateId(value);
    setSelectedLabel(label);
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Input
        id="licensePlate"
        value={licensePlate}
        onChange={handleChangeLicensePlate}
        placeholder="License plate"
      />
      <Dropdown
        value={selectedLabel}
        onChange={({ value, label }) => handleChange({ value, label })}
        optionsList={statesList}
        placeholder={"Select License Plate State"}
        searchPlaceholder="Search..."
      />
      <SearchButtonStyled
        type="button"
        className="search-bar-search-button-class"
        onClick={handleSearch}
        disabled={!licensePlate || !stateId}
      >
        Search
      </SearchButtonStyled>
    </Wrapper>
  );
};

export default LicensePlate;
