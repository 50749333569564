import { FC, memo } from "react";
import { SearchBarProps } from "./types";
import { HeaderStyled } from "./styled";
import { DynamicSelector } from "..";

const SearchBar: FC<SearchBarProps> = ({
  setTab,
  searchError,
  handleShowFilters,
}) => {
  return (
    <HeaderStyled
      className="search-bar-header-class"
    >
      {/* TODO */}
      {/* <HeaderTextWrapper>
        <HeaderText>Header</HeaderText>
      </HeaderTextWrapper> */}

      <DynamicSelector
        setTab={setTab}
        searchError={searchError}
        handleShowFilters={handleShowFilters}
      />
    </HeaderStyled>
  );
};

export default memo(SearchBar);
