import { useEffect, useState } from "react";
import YearMakeModelTab from "./components/YearMakeModel";
import { useGlobalContext } from "./context";
import { LinkWrapper, LinkForTabOpen, TitleWrapper } from "./styles";

const SearchHeader = () => {
  const { state } = useGlobalContext();
  const { isConfigLoading, selectorModalConfig, year, make, model } = state;

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 980);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 980);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const openModal = () => {
    let mainElement: HTMLElement | null =
      document.getElementById("spt-react-app");
    if (!!mainElement) {
      mainElement!.style.display = "block";
    }
  };

  const vinEnabled = selectorModalConfig?.vin_lookup_enabled;
  const licensePlateEnabled = selectorModalConfig?.license_plate_lookup_enabled;

  const noVehicle = !year.label || !make.label || !model.label;

  const showSearchBar = !isMobile && !isConfigLoading;
  const showLink = !isMobile && (vinEnabled || licensePlateEnabled);

  const getTextForLink = () => {
    if (licensePlateEnabled && vinEnabled) {
      return "Click here to search by VIN or License Plate";
    } else if (vinEnabled) {
      return "Click here to search by VIN";
    } else if (licensePlateEnabled) {
      return "Click here to search by License Plate";
    } else return "";
  };

  return (
    <>
      <div className="search-menu-wrapper">
        <div className="info-wrapper">
          <TitleWrapper>
            <span id="fitment-label-id" className="regular-text-box">
              {noVehicle
                ? "Select Vehicle to Get Started"
                : `Current Vehicle: ${year.label} ${make.label} ${model.label}`}
            </span>
          </TitleWrapper>
        </div>
        {showSearchBar && <YearMakeModelTab openApp={true} />}
        {showLink && (
          <LinkWrapper>
            <LinkForTabOpen onClick={openModal} className="regular-text-box">
              {getTextForLink()}
            </LinkForTabOpen>
          </LinkWrapper>
        )}
      </div>
    </>
  );
};

export default SearchHeader;
