import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import $ from "jquery";
import isPropValid from "@emotion/is-prop-valid";
import { StyleSheetManager } from "styled-components";
import { autoPartsSearch, fetchSelectorModalConfig } from "./api/autoParts";
import { Filters, ProductsList, SearchBar } from "./components";
import FilterModal from "./components/FilterModal";
import { Wrapper, ArticleStyled, DivStyled } from "./styles";
import { Loader } from "./common/components";
import {
  SelectorsLocalStorage,
  SptLocalStorageVariables,
} from "./common/constants/localStorage";
import { DynamicSelectorTabs } from "./common/constants/dynamicSelector";
import { setLocalStorageValue } from "./common/helpers";
import { useGlobalContext } from "./context";
import X2JS from "x2js";
import {
  fetchEngines,
  fetchMakes,
  fetchModels,
  fetchPartTypes,
  fetchYears,
} from "./api/showMeTheParts";

const perPage = 20;

const App = ({
  store,
  currency,
}: {
  store: string | null;
  currency: string | null;
}) => {
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [searchError, setSearchError] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<DynamicSelectorTabs>(
    DynamicSelectorTabs.YearMakeModal
  );
  const [count, setCount] = useState<number>(perPage);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModalWindow, setShowModalWindow] = useState<boolean>(false);
  console.log($);

  const { state, dispatch } = useGlobalContext();
  const {
    year,
    make,
    model,
    product,
    engine,
    searchTerm,
    selectorModalConfig,
    isConfigLoading,
    data,
    filters,
  } = state;

  const licenseKey = selectorModalConfig?.license_key;
  const databaseUrl = selectorModalConfig?.database_url;
  const partTypeAllOption =
    selectorModalConfig?.add_all_option_to_ymm_part_type;
  const partTypeEnabled = selectorModalConfig?.part_type_enabled;
  const engineEnabled = selectorModalConfig?.engine_enabled;

  const x2js = new X2JS();
  const getYears = async (licenseKey: any, databaseUrl: any) => {
    if (!licenseKey || !databaseUrl) return;
    dispatch({ type: "setYear", payload: { ...year, isLoading: true } });
    const response = await fetchYears({ id: licenseKey, url: databaseUrl });
    if (response === undefined) {
      dispatch({
        type: "setYear",
        payload: { ...year, isError: true, isLoading: true },
      });
    } else {
      const data: any = x2js?.xml2js(response?.data);
      dispatch({ type: "setYear", payload: { ...year, isLoading: false } });
      dispatch({
        type: "setYearOptions",
        payload: data?.ShowMeTheParts_Year?.year,
      });
    }
  };

  const getMakes = async (licenseKey: any, databaseUrl: any) => {
    if (!year.value || !licenseKey || !databaseUrl) return;
    dispatch({ type: "setMake", payload: { ...make, isLoading: true } });
    const response = await fetchMakes({
      id: licenseKey,
      url: databaseUrl,
      year: year.value,
    });
    if (!response) {
      dispatch({
        type: "setMake",
        payload: { ...make, isError: true, isLoading: false },
      });
    } else {
      const data: any = x2js?.xml2js(response?.data);
      dispatch({ type: "setMake", payload: { ...make, isLoading: false } });
      dispatch({
        type: "setMakeOptions",
        payload: data?.ShowMeTheParts_Make?.make,
      });
    }
  };

  const getModels = async (licenseKey: any, databaseUrl: any) => {
    if (!make.value || !licenseKey || !databaseUrl) return;
    dispatch({ type: "setModel", payload: { ...model, isLoading: true } });
    const response = await fetchModels({
      id: licenseKey,
      url: databaseUrl,
      year: year.value,
      make: make.value,
    });
    if (!response) {
      dispatch({
        type: "setModel",
        payload: { ...model, isError: true, isLoading: false },
      });
    } else {
      const data: any = x2js?.xml2js(response?.data);
      dispatch({ type: "setModel", payload: { ...model, isLoading: false } });
      if (data?.ShowMeTheParts_Model?.model.length) {
        const options = data?.ShowMeTheParts_Model?.model.map((e: any) => {
          return { id: e.id, data: e.data.trim() };
        });
        dispatch({ type: "setModelOptions", payload: options });
      } else {
        const element = data?.ShowMeTheParts_Model?.model;
        dispatch({
          type: "setModelOptions",
          payload: [{ id: element?.id, data: element?.data }],
        });
        dispatch({
          type: "setModel",
          payload: {
            ...model,
            value: element.id,
            label: element.data.trim() || data.id,
          },
        });
        dispatch({
          type: "setProduct",
          payload: { value: "", label: "", isError: false, isLoading: false },
        });
        dispatch({
          type: "setEngine",
          payload: { value: "", label: "", isError: false, isLoading: false },
        });
      }
    }
  };

  const getProducts = async (licenseKey: any, databaseUrl: any) => {
    if (!model.value || !licenseKey || !databaseUrl) return;
    dispatch({ type: "setProduct", payload: { ...product, isLoading: true } });
    const response = await fetchPartTypes({
      id: licenseKey,
      url: databaseUrl,
      year: year.value,
      make: make.value,
      model: model.value,
    });
    if (!response) {
      dispatch({
        type: "setProduct",
        payload: { ...product, isError: true, isLoading: false },
      });
    } else {
      const data: any = x2js?.xml2js(response?.data);
      dispatch({
        type: "setProduct",
        payload: { ...product, isLoading: false },
      });
      const allOption = [
        { data: "ALL", id: "add_all_option_to_ymm_part_type" },
      ];
      const partTypeData = data?.ShowMeTheParts_PartType?.parttype;

      if (!Array.isArray(partTypeData)) {
        dispatch({
          type: "setProduct",
          payload:
            product.label !== partTypeData.data
              ? {
                  ...product,
                  value: partTypeData.id,
                  label: partTypeData.data || partTypeData.id,
                }
              : product,
        });
      } else {
        dispatch({
          type: "setProduct",
          payload: { value: "", label: "", isError: false, isLoading: false },
        });
      }

      const partTypeOptions: any = allOption.concat(partTypeData);

      dispatch({
        type: "setProductOptions",
        payload: partTypeAllOption ? partTypeOptions : partTypeData,
      });
    }
  };

  const getEngines = async (licenseKey: any, databaseUrl: any) => {
    if (!model.value || !licenseKey || !databaseUrl) return;
    dispatch({ type: "setEngine", payload: { ...engine, isLoading: true } });
    const response = await fetchEngines({
      id: licenseKey,
      url: databaseUrl,
      year: year.value,
      make: make.value,
      model: model.value,
      product: product.value,
    });
    if (!response) {
      dispatch({
        type: "setEngine",
        payload: { ...engine, isError: true, isLoading: false },
      });
    } else {
      const data: any = x2js?.xml2js(response?.data);

      const engineOptions = data?.ShowMeTheParts_Engine?.engine;
      if (!Array.isArray(engineOptions)) {
        dispatch({
          type: "setEngine",
          payload:
            engine.label !== engineOptions.data
              ? {
                  ...engine,
                  value: engineOptions.id,
                  label: engineOptions.data || engineOptions.id,
                  isLoading: false,
                }
              : engine,
        });
      } else {
        dispatch({
          type: "setEngine",
          payload: { value: "", label: "", isError: false, isLoading: false },
        });
      }
      dispatch({
        type: "setEngineOptions",
        payload: data?.ShowMeTheParts_Engine?.engine,
      });
    }
  };

  useEffect(() => {
    getMakes(licenseKey, databaseUrl);
  }, [year.value]);
  useEffect(() => {
    getModels(licenseKey, databaseUrl);
  }, [year.value, make.value]);
  useEffect(() => {
    if (partTypeEnabled) getProducts(licenseKey, databaseUrl);
  }, [year.value, make.value, model.value]);
  useEffect(() => {
    if (engineEnabled) getEngines(licenseKey, databaseUrl);
  }, [year.value, make.value, model.value, product.value]);

  const dataConverting = (data: any) => {
    const newFormat = data.map((e: any) => {
      return {
        [e?.part_key]: {
          brand: e?.applications[0].supplier,
          configurations: e.applications.map((el: any) => {
            return {
              comment: el.comment,
              location: el.location,
              application: el.application,
            };
          }),
        },
      };
    });
    const newFormatObject = Object.assign({}, ...newFormat);
    setLocalStorageValue(
      SelectorsLocalStorage.CURRENT_FITMENT_PART_DATA,
      JSON.stringify(newFormatObject)
    );
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    const response = await autoPartsSearch(store, searchTerm);
    if (!response) {
      setSearchError(true);
    }
    dispatch({ type: "setData", payload: response?.data });
    if (response?.data?.data?.data?.products?.length) {
      dataConverting(response.data.data.data.products);
    } else {
      setLocalStorageValue(
        SelectorsLocalStorage.CURRENT_FITMENT_PART_DATA,
        JSON.stringify({})
      );
    }
    setIsLoading(false);
  }, [selectorModalConfig, searchTerm]);

  const Observe = (sel: string, opt: any, cb: (m: MutationRecord) => void) => {
    const Obs = new MutationObserver((m) => [...m].forEach(cb));
    document.querySelectorAll(sel).forEach((el) => Obs.observe(el, opt));
  };

  Observe(
    "#spt-react-app",
    {
      attributesList: ["style"], // Only the "style" attribute
      attributeOldValue: true, // Report also the oldValue
    },
    (m: MutationRecord) => {
      const isDisplayBlock = m?.oldValue?.includes("none");
      setShowModalWindow(!!isDisplayBlock);
      return !!isDisplayBlock;
    }
  );

  useLayoutEffect(() => {
    dispatch({ type: "setStore", payload: store });
    const getSelectorModalConfig = async () => {
      dispatch({ type: "setIsConfigLoading", payload: true });
      const response = await fetchSelectorModalConfig(store);
      dispatch({ type: "setSelectorModalConfig", payload: response.data });
      dispatch({ type: "setIsConfigLoading", payload: false });
      const licenseKey = response.data?.license_key;
      const databaseUrl = response.data?.database_url;
      await getYears(licenseKey, databaseUrl);
      await getMakes(licenseKey, databaseUrl);
      await getModels(licenseKey, databaseUrl);
      await getProducts(licenseKey, databaseUrl);
      await getEngines(licenseKey, databaseUrl);
    };

    getSelectorModalConfig();
    const storageProducts = localStorage.getItem(
      SptLocalStorageVariables.SPT_PRODUCTS
    )
      ? JSON.parse(
          localStorage.getItem(SptLocalStorageVariables.SPT_PRODUCTS) || ""
        )
      : [];
    const storageFilters = localStorage.getItem(
      SptLocalStorageVariables.SPT_FILTERS
    )
      ? JSON.parse(
          localStorage.getItem(SptLocalStorageVariables.SPT_FILTERS) || ""
        )
      : [];
    dispatch({
      type: "setData",
      payload: {
        data: {
          data: { products: storageProducts, searchAttributes: storageFilters },
        },
      },
    });
  }, []);

  const apiRequestError =
    year.isError ||
    make.isError ||
    model.isError ||
    product.isError ||
    engine.isError;

  useEffect(() => {
    if (apiRequestError) dispatch({ type: "setApiError", payload: true });
  }, [apiRequestError]);
  useEffect(() => {
    if (store && !!Object.keys(searchTerm)?.length) {
      fetchData();
    }
  }, [store, searchTerm]);

  useEffect(() => {
    if (filters?.part_type?.includes("all")) {
      dispatch({ type: "setFilters", payload: { ...filters, part_type: "" } });
    }
  }, [filters]);

  const filteredProducts = useMemo(() => {
    if (!data?.data?.data?.products.length) return [];
    localStorage.setItem(
      SptLocalStorageVariables.SPT_PRODUCTS,
      JSON.stringify(data?.data?.data?.products || [])
    );
    return (data?.data?.data?.products).filter((item: any) => {
      const result = Object.keys(filters)?.filter((attribute: any) => {
        if (filters[attribute].toLowerCase() === "all" || !filters[attribute]) {
          return false;
        }

        const filteredItem = item.applications.map((e: any) => {
          const exist = filters[attribute]
            .split("|")
            .some((elem: string) => elem === e[attribute].toLowerCase());
          return exist;
        });

        return filteredItem.some((value: boolean) => value === true);
      });
      return (
        result.length ===
        Object.values(filters)?.filter((item) => !!item && item !== "all")
          .length
      );
    });
  }, [data?.data?.data?.products, filters, searchTerm]);

  const handleShowFilters = () => {
    setShowFilters((prev) => !prev);
  };

  useEffect(() => {
    setCount(perPage);
  }, [fetchData]);

  const isFiltersShown = !!data?.data?.data?.products?.length;

  return (
    <DivStyled
      id="spt-react-app"
      style={{ display: "none" }}
      fontFamily={selectorModalConfig?.search_bar_config?.font_family}
    >
      {!!showModalWindow && (
        <StyleSheetManager shouldForwardProp={isPropValid}>
          {isConfigLoading ? (
            <Loader />
          ) : (
            <Wrapper className="main-wrapper">
              {isLoading && <Loader />}
              <SearchBar
                setTab={setCurrentTab}
                searchError={searchError}
                handleShowFilters={handleShowFilters}
              />
              {!!Object.keys(searchTerm).length && !searchError && (
                <ArticleStyled>
                  {isFiltersShown && (
                    <Filters
                      hideOnMobile={true}
                      onClose={handleShowFilters}
                      currentTab={currentTab}
                    />
                  )}
                  <ProductsList
                    products={filteredProducts}
                    count={count}
                    setCount={setCount}
                    perPage={perPage}
                    currency={currency}
                    isLoading={isLoading}
                  />
                </ArticleStyled>
              )}
              <FilterModal opened={showFilters}>
                <Filters
                  hideOnMobile={false}
                  onClose={handleShowFilters}
                  currentTab={currentTab}
                />
              </FilterModal>
            </Wrapper>
          )}
        </StyleSheetManager>
      )}
    </DivStyled>
  );
};

export default App;
