import { FC, memo, useEffect, useState } from "react";
import { DynamicSelectorProps } from "./types";
import {
  Wrapper,
  TabsStyled,
  TabButton,
  TabsButtonSections,
  TabsWrapper,
  CloseButton,
  ErrorWrapperStyled,
  RegularText,
  MobileText,
  MobileSearchBar,
  CollapsedSearchBar,
  MobileWrapper,
  DesktopWrapper,
  ButtonWrapper,
  TextWrapper,
  CollapsedWrapper,
  ButtonCollapsed,
  TextHeading,
  SearchBarMobile,
  MobileCloseIcon,
  OpenedTransitionWrapper,
  CollapsedTransitionWrapper,
  SearchErrorMessage,
} from "./styled";

import { DynamicSelectorTabs } from "../../common/constants/dynamicSelector";
import { CloseIcon } from "../../common/components";
import { SptLocalStorageVariables } from "../../common/constants/localStorage";
import VIN from "../VIN";
import LicensePlate from "../LicensePlate";
import YearMakeModelTab from "../YearMakeModel";
import { useGlobalContext } from "../../context";

const DynamicSelector: FC<DynamicSelectorProps> = ({
  setTab,
  searchError,
  handleShowFilters,
}) => {
  const [currentTab, setCurrentTab] = useState<DynamicSelectorTabs>(
    DynamicSelectorTabs.YearMakeModal
  );
  const isYearTab = currentTab === DynamicSelectorTabs.YearMakeModal;
  const isVinTab = currentTab === DynamicSelectorTabs.VIN;
  const isLicensePlateTab = currentTab === DynamicSelectorTabs.LicensePlateTab;

  const { state, dispatch } = useGlobalContext();
  const { year, make, model, apiError, selectorModalConfig, collapsed } = state;

  const vinLookupEnabled = selectorModalConfig?.vin_lookup_enabled;
  const licensePlateLookupEnabled =
    selectorModalConfig?.license_plate_lookup_enabled;

  const onlyYearMakeModalTab = !vinLookupEnabled && !licensePlateLookupEnabled;

  const handleCollapsed = () => {
    dispatch({ type: "setCollapsed", payload: !collapsed });
  };

  const handleClose = () => {
    let elem = document.getElementById("spt-react-app");
    if (elem) {
      elem.style.display = "none";
    }

    const keysToRemove = [
      SptLocalStorageVariables.SPT_FILTERS,
      SptLocalStorageVariables.SPT_PRODUCTS,
      SptLocalStorageVariables.SPT_SELECTED_FILTERS,
      SptLocalStorageVariables.SPT_SELECTORS,
    ];

    keysToRemove.forEach((k) => localStorage.removeItem(k));
  };

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
  const handleCollapsedAnimation: any = async () => {
    const el = document.getElementById("mobile-searchbar-id");
    if (el) {
      if (collapsed) {
        el.style.overflow = "hidden";
      } else {
        await delay(500);
        el.style.overflow = "visible";
      }
    }
  };
  useEffect(() => {
    handleCollapsedAnimation();
  }, [collapsed]);

  return (
    <Wrapper>
      {/* mobile */}
      <MobileSearchBar>
        <MobileWrapper>
          <CollapsedTransitionWrapper collapsed={collapsed}>
            <CollapsedSearchBar>
              <CollapsedWrapper>
                <TextWrapper className="search-bar-active-tab-text">
                  Your Vehicle: &nbsp;
                  <div className="search-bar-active-tab-text">{`${year.label} ${make.label} ${model.label}`}</div>
                </TextWrapper>
                <ButtonWrapper>
                  <ButtonCollapsed
                    type="button"
                    className="search-bar-search-button-class"
                    onClick={handleCollapsed}
                  >
                    Edit Vehicle
                  </ButtonCollapsed>
                  <ButtonCollapsed
                    type="button"
                    className="search-bar-search-button-class"
                    onClick={handleShowFilters}
                  >
                    Select Filters
                  </ButtonCollapsed>
                </ButtonWrapper>
              </CollapsedWrapper>
            </CollapsedSearchBar>
          </CollapsedTransitionWrapper>
          <OpenedTransitionWrapper collapsed={collapsed}>
            <SearchBarMobile id="mobile-searchbar-id">
              <TextHeading className="search-bar-active-tab-text">
                Select Your Vehicle
              </TextHeading>
              {!apiError && (
                <TabsButtonSections>
                  {searchError && (
                    <SearchErrorMessage className="search-bar-tab-class">
                      <div className="search-bar-active-tab-class">
                        I'm sorry, but we're having trouble performing that
                        search. Please try again in a few moments
                      </div>
                    </SearchErrorMessage>
                  )}
                  {!onlyYearMakeModalTab && (
                    <TabButton
                      className={
                        currentTab === DynamicSelectorTabs.YearMakeModal
                          ? "search-bar-active-tab-class"
                          : "search-bar-default-tab-class"
                      }
                      isActive={
                        currentTab === DynamicSelectorTabs.YearMakeModal
                      }
                      type="button"
                      onClick={() => {
                        setCurrentTab(DynamicSelectorTabs.YearMakeModal);
                        setTab(DynamicSelectorTabs.YearMakeModal);
                      }}
                    >
                      <RegularText
                        className={
                          currentTab === DynamicSelectorTabs.VIN
                            ? "search-bar-active-tab-text"
                            : "search-bar-default-tab-text"
                        }
                      >
                        Year-Make-Model
                      </RegularText>
                      <MobileText
                        className={
                          currentTab === DynamicSelectorTabs.VIN
                            ? "search-bar-active-tab-text"
                            : "search-bar-default-tab-text"
                        }
                      >
                        Year
                      </MobileText>
                    </TabButton>
                  )}
                  {vinLookupEnabled && (
                    <TabButton
                      className={
                        currentTab === DynamicSelectorTabs.VIN
                          ? "search-bar-active-tab-class"
                          : "search-bar-default-tab-class"
                      }
                      isActive={currentTab === DynamicSelectorTabs.VIN}
                      type="button"
                      onClick={() => {
                        setCurrentTab(DynamicSelectorTabs.VIN);
                        setTab(DynamicSelectorTabs.VIN);
                      }}
                    >
                      VIN
                    </TabButton>
                  )}
                  {licensePlateLookupEnabled && (
                    <TabButton
                      className={
                        currentTab === DynamicSelectorTabs.LicensePlateTab
                          ? "search-bar-active-tab-class"
                          : "search-bar-default-tab-class"
                      }
                      isActive={
                        currentTab === DynamicSelectorTabs.LicensePlateTab
                      }
                      type="button"
                      onClick={() => {
                        setCurrentTab(DynamicSelectorTabs.LicensePlateTab);
                        setTab(DynamicSelectorTabs.LicensePlateTab);
                      }}
                    >
                      License Plate
                    </TabButton>
                  )}
                </TabsButtonSections>
              )}
              <TabsWrapper>
                {apiError || searchError ? (
                  <ErrorWrapperStyled className="search-bar-tab-class">
                    <div className="search-bar-active-tab-class">
                      Currently unable to process part lookups. Please try again
                      later.
                    </div>
                  </ErrorWrapperStyled>
                ) : (
                  <TabsStyled
                    className="search-bar-tab-class"
                    oneTab={onlyYearMakeModalTab}
                  >
                    {isYearTab && <YearMakeModelTab />}
                    {isVinTab && <VIN />}
                    {isLicensePlateTab && <LicensePlate />}
                  </TabsStyled>
                )}
              </TabsWrapper>
            </SearchBarMobile>
          </OpenedTransitionWrapper>
        </MobileWrapper>
        <CloseButton className="search-bar-close-button" onClick={handleClose}>
          <MobileCloseIcon>
            <CloseIcon color={"#ffffff"} />
          </MobileCloseIcon>
        </CloseButton>
      </MobileSearchBar>

      {/* desktop */}
      <DesktopWrapper>
        {!apiError && (
          <TabsButtonSections>
            {searchError && (
              <SearchErrorMessage className="search-bar-tab-class">
                <div className="search-bar-active-tab-class">
                  I'm sorry, but we're having trouble performing that search.
                  Please try again in a few moments
                </div>
              </SearchErrorMessage>
            )}
            {!onlyYearMakeModalTab && (
              <TabButton
                className={
                  currentTab === DynamicSelectorTabs.YearMakeModal
                    ? "search-bar-active-tab-class"
                    : "search-bar-default-tab-class"
                }
                isActive={currentTab === DynamicSelectorTabs.YearMakeModal}
                type="button"
                onClick={() => {
                  setCurrentTab(DynamicSelectorTabs.YearMakeModal);
                  setTab(DynamicSelectorTabs.YearMakeModal);
                }}
              >
                <RegularText
                  className={
                    currentTab === DynamicSelectorTabs.VIN
                      ? "search-bar-active-tab-text"
                      : "search-bar-default-tab-text"
                  }
                >
                  Year-Make-Model
                </RegularText>
                <MobileText
                  className={
                    currentTab === DynamicSelectorTabs.VIN
                      ? "search-bar-active-tab-text"
                      : "search-bar-default-tab-text"
                  }
                >
                  Year
                </MobileText>
              </TabButton>
            )}
            {vinLookupEnabled && (
              <TabButton
                className={
                  currentTab === DynamicSelectorTabs.VIN
                    ? "search-bar-active-tab-class"
                    : "search-bar-default-tab-class"
                }
                isActive={currentTab === DynamicSelectorTabs.VIN}
                type="button"
                onClick={() => {
                  setCurrentTab(DynamicSelectorTabs.VIN);
                  setTab(DynamicSelectorTabs.VIN);
                }}
              >
                VIN
              </TabButton>
            )}
            {licensePlateLookupEnabled && (
              <TabButton
                className={
                  currentTab === DynamicSelectorTabs.LicensePlateTab
                    ? "search-bar-active-tab-class"
                    : "search-bar-default-tab-class"
                }
                isActive={currentTab === DynamicSelectorTabs.LicensePlateTab}
                type="button"
                onClick={() => {
                  setCurrentTab(DynamicSelectorTabs.LicensePlateTab);
                  setTab(DynamicSelectorTabs.LicensePlateTab);
                }}
              >
                License Plate
              </TabButton>
            )}
          </TabsButtonSections>
        )}
        <TabsWrapper>
          {apiError || searchError ? (
            <ErrorWrapperStyled className="search-bar-tab-class">
              <div className="search-bar-active-tab-class">
                Currently unable to process part lookups. Please try again
                later.
              </div>
            </ErrorWrapperStyled>
          ) : (
            <TabsStyled
              className="search-bar-tab-class"
              oneTab={onlyYearMakeModalTab}
            >
              {isYearTab && <YearMakeModelTab />}
              {isVinTab && <VIN />}
              {isLicensePlateTab && <LicensePlate />}
            </TabsStyled>
          )}

          <CloseButton
            className="search-bar-close-button"
            onClick={handleClose}
          >
            <CloseIcon color={"#ffffff"} />
          </CloseButton>
        </TabsWrapper>
      </DesktopWrapper>
    </Wrapper>
  );
};

export default memo(DynamicSelector);
